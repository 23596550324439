import { twMerge } from 'tailwind-merge'

const Button = ({ href, size = 'xl', className, children, ...props }) => {
  const style = size === 'sm' ? 'px-2 h-10 rounded-lg' : 'px-3 h-16 rounded-3xl'

  return href ? (
    <a
      href={href}
      className={twMerge(
        `focus:outline-none py-3 ${style} inline-flex justify-start items-center`,
        className
      )}
      {...props}
    >
      {children}
    </a>
  ) : (
    <button
      className={twMerge(
        `focus:outline-none py-3 ${style} inline-flex justify-start items-center`,
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
