import { useLayoutEffect, useRef } from 'react'
import BaseModal from 'react-modal'

BaseModal.setAppElement('#root')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    zIndex: 9999999,
    backgroundColor: '#111418b3',
    display: 'flex'
  }
}

const noop = () => []

const Modal = ({ open, children, onClose = noop }) => {
  const overflow = useRef()

  useLayoutEffect(() => {
    if (!open) {
      document.body.style.overflow = overflow.current
      return
    }

    overflow.current = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = 'hidden'
    return () => (document.body.style.overflow = overflow.current)
  }, [open])

  return (
    <BaseModal
      isOpen={open}
      style={customStyles}
      onRequestClose={() => onClose()}
      className='w-full sm:w-[500px] md:w-w[600px] focus:outline-none absolute z-50 top-[50%] mr-[50%] flex-1 inline-flex justify-center items-center m-auto'
    >
      {children}
    </BaseModal>
  )
}

export default Modal
