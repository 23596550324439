import DownloadButton from 'components/DownloadButton'

const Hero = () => {
  return (
    <section className='w-full px-8 xs:px-10 antialiased'>
      <div className='container m-auto max-w-5xl flex flex-col items-center relative'>

        <div className='mt-32 mb-32 z-[5] flex flex-col items-center'>
          <h1 className='text-center font-bold text-6xl mb-4 bg-brand1 bg-clip-text text-transparent'>
            Peer-to-peer chat
          </h1>
          <h2 className='text-center text-2xl mb-4'>
            Video & text. Private & encrypted.
          </h2>
          <h2 className='text-center font-bold text-2xl mb-6'>
            Unparalleled quality.
          </h2>
          <DownloadButton />
        </div>

        <div className='hidden md:block bg-hero-corner-left w-[500px] h-[300px] absolute bottom-0 left-0 bg-center bg-cover'/>
        <div className='hidden md:block bg-hero-corner-right w-[500px] h-[300px] absolute top-0 right-0 bg-center bg-cover'/>
      </div>
    </section>
  )
}

export default Hero
