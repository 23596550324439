import { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from 'components/Layout'

const Terms = () => {

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <section className='terms w-full px-6 sm:px-16  antialiased mb-20 text-sm'>
        <div className='content px-2 sm:px-16'>
          <Link to='/' className='flex hover:text-white'>
            <svg
              viewBox='0 0 33 22'
              xmlns='http://www.w3.org/2000/svg'
              className='w-4 mr-4 text-white'
              fill='currentColor'
              stroke='none'
            >
              <path d='M12 1.4375C12.3125 1.75 12.5 2.125 12.5 2.5C12.5 2.9375 12.3125 3.3125 12 3.5625L6.0625 9.5H31.5C32.3125 9.5 33 10.1875 33 11C33 11.875 32.3125 12.5 31.5 12.5H6.0625L12 18.5C12.625 19.0625 12.625 20 12 20.5625C11.4375 21.1875 10.5 21.1875 9.9375 20.5625L1.4375 12.0625C0.8125 11.5 0.8125 10.5625 1.4375 10L9.9375 1.5C10.5 0.875 11.4375 0.875 12 1.4375Z' />
            </svg>
            <span className='text-sm'>Back</span>
          </Link>

          <h1 className='text-2xl font-bold py-6'>Terms of Service</h1>

          <h2>
            PLEASE READ THESE TERMS OF USE (“TERMS”) CAREFULLY BEFORE USING THIS
            WEBSITE (“SITE”) OR THE SOFTWARE PRODUCTS DESCRIBED ON THIS WEBSITE
            (“PRODUCTS”).
          </h2>

          <br />

          <p>
            PLEASE REVIEW THE ARBITRATION PROVISION SET FORTH BELOW CAREFULLY,
            AS IT WILL REQUIRE ALL PERSONS TO RESOLVE DISPUTES ON AN INDIVIDUAL
            BASIS THROUGH FINAL AND BINDING ARBITRATION AND TO WAIVE ANY RIGHT
            TO PROCEED AS A REPRESENTATIVE OR CLASS MEMBER IN ANY CLASS OR
            REPRESENTATIVE PROCEEDING. BY{' '}
            <span>
              ACCESSING OR USING THE SITE OR DOWNLOADING OR USING THE PRODUCTS,
            </span>
            YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF
            THE TERMS OF THIS ARBITRATION PROVISION AND HAVE TAKEN TIME TO
            CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.
          </p>

          <br />
          <p>What&#39;s in these terms?</p>

          <p>
            These Terms tell you the rules for using our Site and Products.{' '}
            <b>
              By accessing or using our Site or downloading or using our
              Products, you confirm that you accept these terms and agree to
              comply with them.{' '}
            </b>
            If you do not agree to these terms, you must not use our Site or
            Products. We recommend printing a copy of these terms for future
            reference.
          </p>

          <br />

          <p>
            These terms were last updated on <strong>December 21, 2022.</strong>
          </p>

          <br />
          <p>
            Click on the links below to go straight to more information on each
            area:
          </p>

          <ul>
            <li>
              <p>
                <a href='#section-1' className='hover:no-underline'>
                  ● Who we are and how to contact us
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-2' className='hover:no-underline'>
                  ● What we do
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-3' className='hover:no-underline'>
                  ● System requirements
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-4' className='hover:no-underline'>
                  ● Additional terms may apply to our Products
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-5' className='hover:no-underline'>
                  ● Using our Products
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-6' className='hover:no-underline'>
                  ● We may make changes to these Terms
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-7' className='hover:no-underline'>
                  ● We may make changes to our Site or Products, including based
                  on your feedback
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-8' className='hover:no-underline'>
                  ● We may suspend or withdraw our Site or our Products
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-9' className='hover:no-underline'>
                  ● We may transfer this agreement to someone else
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-10' className='hover:no-underline'>
                  ● Our Site and Products are not for certain users
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-11' className='hover:no-underline'>
                  ● Do not rely on information on this Site
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-12' className='hover:no-underline'>
                  ● We are not responsible for websites we link to
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-13' className='hover:no-underline'>
                  ● Rules about linking to our Site
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-14' className='hover:no-underline'>
                  ● Our responsibility for loss or damage suffered by you
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-15' className='hover:no-underline'>
                  ● We are not responsible for viruses and you must not
                  introduce them
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-16' className='hover:no-underline'>
                  ● Injunctive relief
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-17' className='hover:no-underline'>
                  ● Indemnity
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-privacy' className='hover:no-underline'>
                  ● Privacy
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-access' className='hover:no-underline'>
                  ● Access Through Applications
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-18' className='hover:no-underline'>
                  ● General (including mandatory arbitration)
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='#section-19' className='hover:no-underline'>
                  ● Our trademarks
                </a>
              </p>
            </li>
          </ul>

          <h3 id='section-1'>Who we are and how to contact us</h3>

          <p>
            The Site is operated by Holepunch Inc. (“<b>We</b>”, “<b>us</b>” and
            “<b>our</b>”), a limited company registered in the British Virgin
            Islands under company number 2009672. Our registered office is at
            Trinity Chambers, PO Box 4301, Road Town, Tortola VG1110, British
            Virgin Islands. To contact us, please email{' '}
            <a
              rel='noreferrer'
              href='mailto:hello@holepunch.to'
              target='_blank'
            >
              hello@holepunch.to.
            </a>
          </p>

          <h3 id='section-2'>What we do</h3>

          <p>
            We have developed and released the software Product called ‘Keet’,
            which provides a peer-to-peer videoconferencing platform (“
            <b>Keet</b>
            ”). Keet was originally developed by Holepunch Inc., and certain
            versions are available for use on an open-source and fee-free basis,
            subject to these Terms.
          </p>

          <h3 id='section-3'>System requirements</h3>

          <p>
            Use of our Site and our Products requires one or more compatible
            devices, internet access (fees may apply), and may require obtaining
            updates or upgrades from time to time. Because use of our Site or
            our Products involves hardware, software, and internet access, your
            ability to access and use the Site and the Products on it may be
            affected by the performance of these factors. High-speed internet
            access is recommended. You acknowledge and agree that such system
            requirements, which may change from time to time, are your
            responsibility.
          </p>

          <h3 id='section-4'>Additional terms may apply to our Products</h3>

          <p>
            Use of some Products may be subject to additional terms, license
            agreements, privacy terms, export terms, subscription agreements, or
            other terms and conditions (“<b>Additional Terms</b>”) that apply to
            your access to or use of the applicable Products. In the event of a
            conflict, inconsistency, or difference between these Terms and the
            Additional Terms, the Additional Terms will control. We may add or
            revise these terms at any time without notice to you. The revised
            terms will be effective when posted. Your continued use of the
            Products, as applicable, after changes to the terms have been
            published constitutes your binding acceptance of the updated terms.
            If at any time the Terms are no longer acceptable to you, you should
            immediately cease all use of our Site and our Products.
          </p>

          <p>
            THESE TERMS AND THE ADDITIONAL TERMS (WHERE APPLICABLE) FORM A
            LEGALLY BINDING AGREEMENT BETWEEN YOU AND HOLEPUNCH INC. THEREFORE,
            YOU MUST READ THEM CAREFULLY BEFORE ACCESSING OUR SITE OR PRODUCTS.
            TO USE OUR SITE OR OUR PRODUCTS, YOU MUST FIRST AGREE TO THESE
            TERMS. YOU MAY NOT ACCESS OR USE OUR SITE OR PRODUCTS IF YOU DO NOT
            ACCEPT THESE TERMS. BY USING THE SITE OR OUR PRODUCTS, YOU
            ACKNOWLEDGE THAT YOU HAVE READ AND AGREE TO THESE TERMS. IF YOU ARE
            AN INDIVIDUAL ACTING ON BEHALF OF A CORPORATION OR OTHER LEGAL
            ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO AGREE TO THESE
            TERMS ON BEHALF OF THE LEGAL ENTITY. IF YOU DO NOT AGREE TO THESE
            TERMS, YOU MAY NOT ACCESS OR USE THE SITE OR ITS PRODUCTS.
          </p>

          <h3 id='section-5'>Using Our Products</h3>
          <h4 id='section-5-1'>How you may use material on our Site</h4>

          <p>
            We are the owner or the licensee of all intellectual property rights
            on our Site and in the material published on it. Those works are
            protected by copyright laws and treaties around the world. All such
            rights are reserved.
          </p>

          <p>
            We grant you a personal, non-assignable license to use our Site and
            our Products for your own internal use. In the event of a conflict,
            inconsistency, or difference between these Terms and the terms of
            any end-user license agreement and/or other agreements between you
            and Holepunch Inc. and/or third parties, those terms will control.
            If you exceed your authorized use of our Site or our Products, you
            may be required to cease using them and, at our option, return or
            destroy any copies of the materials you have made. In addition, your
            right to continue accessing our Site and our Products is subject to
            your continued compliance with these Terms and any Additional Terms.
          </p>

          <p>
            You may print off one copy and download extracts of any page(s) from
            our Site for your personal use, and you may draw the attention of
            others within your organisation to content posted on our Site,
            provided that you comply with any Additional Terms.
          </p>

          <p>
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, including the removal of
            any copyright notice, and you must not use any illustrations,
            photographs, video or audio sequences or any graphics separately
            from any accompanying text.
          </p>

          <p>
            Our status (and that of any identified contributors) as site content
            authors and our Products must always be acknowledged.
          </p>

          <p>
            You must not use any part of the content on our Site or our Products
            for commercial purposes without obtaining a licence to do so from us
            or our licensors.
          </p>

          <h4>Open Source Software</h4>

          <p>
            Certain of our Products are offered under, and these Terms are
            subject to, the terms of an open-source software licence, under
            which our Products are licensed to you. Open-source software
            licences constitute separate written agreements. To the limited
            extent that the open-source software licence expressly supersedes
            these terms, the open-source software licence instead sets out your
            agreement with us.
          </p>

          <h4>Use Restrictions</h4>

          <p>
            You must access and use our Site and Products only in compliance
            with all applicable laws, regulations and third-party rights and in
            accordance with these terms. You agree to, and will not attempt to
            circumvent, such limitations. Without limiting the foregoing, you
            will not use our Site or our Products in a manner that:
          </p>

          <ol>
            <li>
              <p>
                (a) infringes on our or any third party’s intellectual property
                rights or rights of publicity or privacy
              </p>
            </li>
            <li>
              <p>
                (b) is defamatory, trade libelous, threatening, harassing
                (including auto-dialing, bulk messaging or other unsolicited
                actions);
              </p>
            </li>
            <li>
              <p>
                (c) contains any viruses or other computer programming routines
                that may damage, detrimentally interfere with, surreptitiously
                intercept, or expropriate any system or data; or
              </p>
            </li>
            <li>
              <p>
                (d) otherwise violates, or encourages or promotes the violation
                of, any applicable law or third party right.
              </p>
            </li>
          </ol>

          <h4>Content Transmitted by You</h4>

          <p>
            Our Products allow you to transmit content. You are solely
            responsible for that content, including whether you need the consent
            of any third party to use and distribute that content. We shall not
            be liable for any content transmitted or viewed through our
            Products.
          </p>

          <h4>Prohibited Use</h4>

          <p>
            You agree that you will not use the Site or Products to (each “
            <b>Objectionable Conduct</b>”): (i) modify, disassemble, decompile,
            prepare derivative works of, reverse engineer or otherwise attempt
            to gain access to the source code of the Site; (ii) knowingly or
            negligently use the Site in a way that abuses, interferes with, or
            disrupts our networks, or the Site; (iii) engage in activity that is
            illegal, fraudulent, false, or misleading, (iv) transmit through our
            Site or Products any material that may infringe the intellectual
            property or other rights of third parties; (v) copy any features,
            functions or graphics of the Site; or (vi) use our Site or our
            Products to communicate any message or material that is harassing,
            libelous, threatening,
          </p>
          <p>
            obscene, indecent, would violate the intellectual property rights of
            any party or is otherwise unlawful, that would give rise to civil
            liability, or that constitutes or encourages conduct that could
            constitute a criminal offense, under any applicable law or
            regulation, violence, terrorism, endangerment, harm, violence,
            abuse, exploitation to individuals, racial, religious or ethnic
            discrimination, or any other actions generally viewed as
            inappropriate or offensive; (vii) upload or transmit any software,
            content or code that does or is intended to harm, disable, destroy
            or adversely affect performance of the Site in any way or which does
            or is intended to harm or extract information or data from other
            hardware, software or our Products or networks or other users of the
            Site;
          </p>
          <p>
            (viii) engage in any activity or use the Site in any manner that
            could damage, disable, overburden, impair or otherwise interfere
            with or disrupt the Site, or any servers or networks connected to
            the Site or our security systems. (ix) use the Site or Products in
            violation of any of our policies or in a manner that violates
            applicable law, including but not limited to anti-spam, export
            control, privacy, and anti-terrorism laws and regulations and laws
            requiring the consent of subjects of audio and video recordings, and
            you agree that you are solely responsible for compliance with all
            such laws and regulations.
          </p>
          <p>
            We have no tolerance for Objectionable Content or users who abuse
            the use of our Site or Products in contravention of these Terms.
          </p>

          <h4>No Representations and Warranties</h4>

          <p>
            We make no representations, warranties, covenants or guarantees to
            you of any kind and, to the extent permitted by applicable laws, we
            expressly disclaim all representations, warranties, covenants or
            guarantees, express, implied or statutory, with respect to our
            Products and Site. Our Products and Site are offered strictly on an
            as-is, where-is basis and, without limiting the generality of the
            foregoing, are provided without any representation as to
            merchantability or fitness for any particular purpose or any
            representation or warranty that our Products and Site will be
            operational, error-free, secure, confidential, reliable, or safe, or
            that our Products and Site will function without disruptions, delays
            or imperfections.
          </p>

          <h4>Non-Exclusivity</h4>

          <p>
            Our Products are non-exclusive. You acknowledge that we may develop
            products or services that compete with our Products (including
            premium versions of Products) or any other products or services.
          </p>

          <h3 id='section-6'>We may make changes to these Terms</h3>

          <p>
            We amend these Terms from time to time. Every time you wish to use
            our Site or Products, please check these Terms to ensure you
            understand the terms that apply at that time. When we do this, we
            will post the revised Terms on this page and indicate the date of
            such amendments.
          </p>

          <h3 id='section-7'>
            We may make changes to our Site or Products, including based on your
            feedback
          </h3>

          <p>
            We may update and change our Site or Products from time to time and
            without notice to you.
          </p>

          <p>
            If you provide feedback or suggestions about our Products, Site, or
            any content available on our Site, then we may act on that feedback
            or those suggestions on an unrestricted basis and without any
            obligations to you (including any obligation to compensate you or to
            keep that feedback confidential).
          </p>

          <h3 id='section-8'>
            We may suspend or withdraw our Site or Products
          </h3>

          <p>
            We do not guarantee that our Products, Site, or any content on it
            will always be available or uninterrupted. We may suspend, withdraw,
            or restrict the availability of all or any part of our Site and our
            Products for business and operational reasons. We will try to give
            reasonable notice of any suspension or withdrawal by notice on the
            Site.
          </p>

          <h3 id='section-9'>We may transfer this agreement to someone else</h3>

          <p>
            These terms, and any of the rights, duties, and obligations
            contained or incorporated herein, are not transferable by you
            without our prior written consent, and any attempt by you to
            transfer these Terms without such consent will be void.
          </p>

          <p>
            We are permitted to transfer (or assign) these Terms and any of the
            rights, duties, and obligations contained herein, in whole or in
            part, to another organisation without notice or your consent.
          </p>

          <h3 id='section-10'>
            Our Site and Products are not for certain users
          </h3>

          <p>
            You must be at least 18 years of age or, if higher, the age at which
            you are legally recognised as an adult in your jurisdiction to use
            our Site and Products.
          </p>

          <h3 id='section-11'>Do not rely on information on this Site</h3>

          <p>
            The content on our Site is provided for general information only. It
            is not intended to amount to a recommendation or advice on which you
            should rely. You must obtain professional or specialist advice
            before taking, or refraining from, any action based on the content
            on our Site.
          </p>

          <p>
            Although we make reasonable efforts to update the information on our
            Site, we make no representations, warranties or guarantees, whether
            express or implied, that the content on our Site is accurate,
            complete or up to date.
          </p>

          <h3 id='section-12'>
            We are not responsible for websites we link to
          </h3>

          <p>
            Where our Site contains links to other sites and resources provided
            by third parties or affiliates, these links are only provided for
            your information. Such links should not be interpreted as approval
            by us of those linked websites or information you may obtain from
            them.
          </p>

          <p>
            We have no control over the contents of those sites or resources, or
            their privacy settings, policies and/or procedures. You are solely
            responsible for reviewing the information provided by those sites or
            resources.
          </p>

          <h3 id='section-13'>Rules about linking to our Site</h3>

          <p>
            You may link to our home page, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it. You must not establish a link in such a way as to suggest any
            form of association, approval or endorsement on our part where none
            exists.
          </p>

          <p>
            You must not establish a link to our Site on any website you do not
            own.
          </p>

          <p>
            Our Site must not be framed on any other site, nor may you create a
            link to any part of our Site other than the home page.
          </p>

          <p>
            We reserve the right to withdraw linking permission without notice.
            If you wish to link to or use the content on our Site other than
            that set out above, please contact us through the email address
            provided above.
          </p>

          <h3 id='section-14'>
            Our responsibility for loss or damage suffered by you
          </h3>

          <p>
            We do not exclude or limit our liability to you where it would be
            unlawful to do so. This includes liability for death or personal
            injury caused by our negligence or the negligence of our employees,
            agents or subcontractors and for fraud or fraudulent
            misrepresentation.
          </p>

          <p>
            We exclude all implied conditions, warranties, representations or
            other terms that may apply to our Products, Site or any content on
            it.
          </p>

          <p>
            To the extent permitted by applicable law, we will not be liable to
            you for any loss or damage, whether in contract, tort (including
            negligence), breach of statutory duty, or otherwise, even if
            foreseeable, arising under or in connection with:
          </p>

          <ul>
            <li>
              <p>● use of, or inability to use, our Site or our Products; or</p>
            </li>
            <li>
              <p>● use of or reliance on any content displayed on our Site.</p>

              <p>In particular, we will not be liable for:</p>
            </li>
            <li>
              <p>● loss of profits, sales, business, or revenue;</p>
            </li>
            <li>
              <p>● business interruption;</p>
            </li>
            <li>
              <p>● loss of anticipated savings;</p>
            </li>
            <li>
              <p>● loss of business opportunity, goodwill or reputation; or</p>
            </li>
            <li>
              <p>● any indirect or consequential loss or damage.</p>
            </li>
          </ul>

          <h3 id='section-15'>
            We are not responsible for viruses and you must not introduce them
          </h3>

          <p>
            We do not guarantee that our Products, Site or any content on it
            will be secure or free from bugs or viruses.
          </p>

          <p>
            You are responsible for configuring your information technology,
            computer programmes and platform to access our Site and our
            Products. You should use your own virus protection software.
          </p>

          <p>
            You must not misuse our Site or our Products by knowingly
            introducing viruses, trojans, worms, logic bombs or other malicious
            or technologically harmful material. You must not attempt to gain
            unauthorised access to our Site, the server on which our Site is
            stored or any server, computer or database connected to our Site.
            You must not attack our Site via a denial-of-service attack or a
            distributed denial-of-service attack. You may commit a criminal
            offence under the BVI Computer Misuse and Cybercrime Act 1990 or
            other applicable legislation by breaching this provision. We will
            report any such breach to the relevant law enforcement authorities
            and co-operate with those authorities by disclosing your identity to
            them. In the event of such a breach, your right to use our Site will
            cease immediately.
          </p>

          <h3 id='section-16'>Injunctive relief</h3>

          <p>
            You acknowledge that any use of our Site or Products contrary to
            these Terms, or any unpermitted transfer, sublicensing, copying or
            disclosure of technical information or materials related to our Site
            or our Products, may cause irreparable injury to us or our
            affiliates. Under such circumstances, we or our affiliates will be
            entitled to equitable relief without posting a bond or other
            security, including, but not limited to, preliminary and permanent
            injunctive relief.
          </p>

          <h3 id='section-17'>Indemnity</h3>

          <p>
            You agree only to use the Site and our Products in accordance with
            these terms. You agree that you will compensate Holepunch Inc. (and
            our employees, contractors, directors, officers, agents, affiliates
            and suppliers) in full for any damages, losses, costs and expenses,
            including reasonable legal fees, we incur as a result of any breach
            by you of these terms or any liability we incur because of the use
            of our Site or our Products by you.
          </p>

          <p>
            You are also responsible for ensuring that all persons who access
            our Site through your internet connection are aware of these terms
            of use and other applicable terms and conditions and that they
            comply with them.
          </p>

          <h3 id='section-privacy'>Privacy</h3>

          <p>
            We do not collect or otherwise process any Personal Information from
            you. By “Personal Information”, we mean any information which,
            either alone or in combination with other data, enables you to be
            directly or indirectly identified, for example, your name, email
            address, username, contact details or any unique identifier such as
            an IP address, device ID or other online identifier. For the
            avoidance of doubt, we do not maintain mailing lists, use cookies
            for advertising or analytics or take customer inquiries via email,
            contact forms or social media.
          </p>

          <h3 id='section-access'>Access Through Applications</h3>

          <p>
            <i>Access Through Keet App for iOS</i>: If you are accessing or
            using the Products through the application we make available for
            download from the Apple Inc. (Apple Inc. together with all of its
            affiliates, “<b>Apple</b>”) application store (the “
            <b>Keet App for iOS</b>”) the following terms and conditions apply
            to you in addition to all the other terms and conditions of these
            Terms in respect of your access to or use of the Products through
            the Keet App for iOS: (i) these Terms are concluded between you and
            Holepunch Inc., and not with Apple and Apple is not responsible for
            the Products and content thereof is governed by these Terms; (ii)
            notwithstanding anything to the contrary hereunder, you may use the
            Keet App for iOS only on an Apple branded device; (iii) you
            acknowledge that Apple has no obligation to furnish any maintenance
            or support services with respect to the Products (including the Keet
            App for iOS); (iv) in the event of any failure of the Keet App for
            iOS to conform to any applicable warranty, you may notify Apple, and
            Apple will refund the purchase price for the Keet App for iOS (if
            any) to you; except for the foregoing, to the maximum extent
            permitted by applicable law, Apple will have no other warranty
            obligation whatsoever with respect to the Products (including the
            Keet App for iOS), and any other claims, losses, liabilities,
            damages, costs or expenses attributable to any failure to conform to
            any warranty will be governed by these Terms; (v) any claim in
            connection with the Products related to product liability, a failure
            to conform to applicable legal or regulatory requirements, or claims
            under consumer protection or similar legislation is governed by
            these Terms, and Apple is not responsible for such claim; (vi) any
            third party claim that the Products or your possession and use of
            the Keet App for iOS infringes that third party’s intellectual
            property rights will be governed by these Terms, and Apple will not
            be responsible for the investigation, defense, settlement and
            discharge of such intellectual property infringement claim; (vii)
            you represent and warrant that you are not: (a) located in any
            country that is subject to a U.S. government embargo, or that has
            been designated by the U.S. government as a “terrorist supporting”
            country; or (b) listed on any U.S. government list of prohibited or
            restricted parties; (viii) you may contact Holepunch Inc. in writing
            regarding any notices, questions, complaints or claims with respect
            to the Products (including the Keet App for iOS) by email to
            hello@holepunch.to; and (ix) Apple is a third party beneficiary to
            these Terms and may enforce these Terms against you.
          </p>

          <p>
            <i>Access Through Keet App for Android</i>: If you are accessing or
            using the Products through the application we make available for
            download from the ‘Google Play’ application store made available by
            Google LLC (Google LLC together with all of its affiliates, “
            <b>Google</b>”, such application the “<b>Keet App for Android</b>”)
            the following terms and conditions apply to you in addition to all
            the other terms and conditions of these Terms in respect of your
            access to or use of the Products through the Keet App for Android:
            (i) these Terms are concluded between you and Holepunch Inc., and
            not with Google and Google is not responsible for the Products and
            content thereof is governed by these Terms; (ii) you acknowledge
            that Google has no obligation to furnish any maintenance or support
            services with respect to the Products (including the Keet App for
            Android); (iii) to the maximum extent permitted by applicable law,
            Google will have no warranty obligation whatsoever with respect to
            the Products (including the Keet App for Android), and any other
            claims, losses, liabilities, damages, costs or expenses attributable
            to any failure to conform to any warranty will be governed by these
            Terms; (iv) any claim in connection with the Products related to
            product liability, a failure to conform to applicable legal or
            regulatory requirements, or claims under consumer protection or
            similar legislation is governed by these Terms, and Google is not
            responsible for such claim; (v) any third party claim that the
            Products or your possession and use of the Keet App for Android
            infringes that third party’s intellectual property rights will be
            governed by these Terms, and Google will not be responsible for the
            investigation, defense, settlement and discharge of such
            intellectual property infringement claim; (vi) you may contact
            Holepunch Inc. in writing regarding any notices, questions,
            complaints or claims with respect to the Products (including the
            Keet App for Android) by email to hello@holepunch.to; and (vii)
            Google is a third party beneficiary to these Terms and may enforce
            these Terms against you.
          </p>

          <h3 id='section-18'>General (including mandatory arbitration)</h3>

          <p>
            Entire agreement
            <span>
              : These terms of use constitute the entire agreement between you
              and Holepunch Inc. and supersede any prior agreements between you
              and Holepunch Inc. You may also be subject to additional terms of
              service that may apply when you use affiliate or third-party
              services, third-party content or third-party software.
            </span>
          </p>

          <p>
            Governing Law:{' '}
            <span>
              These Terms shall be governed by and construed and enforced in
              accordance with the laws of the British Virgin Islands and shall
              be interpreted in all respects as a BVI contract. Any transaction,
              dispute, controversy, claim or action arising from or related to
              your access or use of our Site or our Products or these Terms
              shall be governed by the laws of the British Virgin Islands,
              exclusive of choice-of-law principles.
            </span>
          </p>

          <p>
            Mandatory Arbitration:{' '}
            <span>
              Except for excluded claims described below, you and we each agree
              that any dispute, claim or controversy arising out of or relating
              to (a) these Terms or the existence, breach, termination,
              enforcement, interpretation or validity thereof, or (b) your
              access to or use of our Site or our Products at any time, will be
              subject to and finally resolved by confidential, binding
              arbitration and not in a class, representative or consolidated
              action or proceeding. If you are subject to the laws of the United
              States of America, the interpretation and enforceability of this
              arbitration provision will be governed by the
            </span>
          </p>
          <p>
            Federal Arbitration Act, 9 U.S.C. §§ 1 <i>et seq</i>. Arbitration
            will be conducted through the use of videoconferencing technology
            (unless both arbitration parties agree that an in-person hearing is
            appropriate given the nature of the dispute) before a single
            arbitrator in accordance with the British Virgin Islands
            International Arbitration Centre Arbitration Rules, as amended from
            time to time (the “BVI IAC Rules”). The arbitrator selected by the
            arbitration parties must be a licenced attorney in the British
            Virgin Islands with at least fifteen (15) years of experience in
            commercial disputes. If the arbitration parties do not jointly
            appoint the arbitrator within thirty (30) days of the commencement
            of the arbitration, any vacancies will be filled by an arbitrator
            meeting the above qualifications selected by the British Virgin
            Islands Arbitration Centre in accordance with the BVI IAC Rules.
            Judgment upon the award rendered by the arbitrator may be entered by
            any court having jurisdiction thereof. If the arbitral parties do
            not promptly agree to the seat of arbitration if an in-person
            hearing is selected, the seat will be the British Virgin Islands.
            The language of the arbitral proceedings will be English. The
            arbitrator may award any relief that a court of competent
            jurisdiction could award, including attorneys’ fees when authorized
            by law, and the arbitral decision may be enforced in court. For
            claims less than $15,000, we will reimburse you for all initiating
            filing fees. Should you be deemed the losing party, the filing fees
            reimbursed by us will be added to the final arbitrator’s costs and
            fees award. The prevailing party will be entitled to its costs of
            the arbitration (including the arbitrator’s fees) and its reasonable
            attorney’s fees and costs.
          </p>

          <p>
            The following claims and causes of action will be excluded from
            arbitration as described in the paragraphs above: causes of action
            or claims in which either party seeks injunctive or other equitable
            relief for the alleged unlawful use of its intellectual property,
            including without limitation copyrights, trademarks, trade names,
            trade secrets, or patents or its confidential information or private
            data. Parties shall be at liberty to pursue claims or causes of
            actions excluded from arbitration through the courts of competent
            jurisdiction.
          </p>

          <p>
            The arbitrator will have the power to hear and determine challenges
            to its jurisdiction, including any objections with respect to the
            existence, scope or validity of the arbitration agreement. This
            authority extends to jurisdictional challenges with respect to both
            the subject matter of the dispute and the parties to the
            arbitration. Further, the arbitrator will have the power to
            determine the existence, validity, or scope of the contract of which
            an arbitration clause forms a part. For the purposes of challenges
            to the arbitrator&#39;s jurisdiction, each clause within this
            section will be considered separable from any contract of which it
            forms a part. Any challenges to the jurisdiction of the arbitrator,
            except challenges based on the award itself, will be made not later
            than the notice of defense or, with respect to a counterclaim, the
            reply to the counterclaim; provided, however, that if a claim or
            counterclaim is later added or amended such a challenge may be made
            not later than the response to such claim or counterclaim as
            provided under BVI IAC Rules.
          </p>

          <p>
            You and we expressly intend and agree that: (a) class action and
            representative action procedures are hereby waived and will not be
            asserted, nor will they apply, in any arbitration pursuant to these
            Terms; (b) neither you nor we will assert class action or
            representative action claims against the other in arbitration or
            otherwise; (c) each of you and us will only submit our own,
            individual claims in arbitration and will not seek to represent the
            interests of any other person, or consolidate claims with any other
            person; (d) nothing in these Terms will be interpreted as your or
            our intent to arbitrate claims on a class or representative basis;
            and (e) any relief awarded to any one user of our Site or Products
            cannot and may not affect any other user. No adjudicator may
            consolidate or join more than one person’s claims and or otherwise
            preside over any form of a consolidated, representative, or class
            proceeding.
          </p>

          <p>
            You, we and any other arbitration parties will maintain the
            confidential nature of the arbitration proceeding and any award,
            including the hearing, except as may be necessary to prepare for or
            conduct the arbitration hearing on the merits, or except as may be
            necessary in connection with a court application for a preliminary
            remedy, a judicial challenge to an award or its enforcement, or
            unless otherwise required by law or judicial decision.
          </p>

          <p>
            JURY TRIAL WAIVER: TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
            LAW, YOU AND WE HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVE ALL
            RIGHT TO TRIAL BY JURY IN ANY LEGAL ACTION OR PROCEEDING OF ANY KIND
            WHATSOEVER ARISING OUT OF OR RELATING TO THESE TERMS OR ANY BREACH
            THEREOF, ANY USE OR ATTEMPTED USE OF OUR SITE OR OUR PRODUCTS BY
            YOU, AND/OR ANY OTHER MATTER INVOLVING YOU AND US.
          </p>
          <p>
            Severability
            <span>
              : If an adjudicator of competent jurisdiction finds any provision
              of these Terms to be (or are otherwise) invalid, you nevertheless
              agree that the adjudicator should endeavour to give effect to the
              parties’ intentions as reflected in the provision, and the other
              provisions of these terms shall remain in full force and effect.
            </span>
          </p>

          <p>
            Waiver
            <span>
              : If you breach these terms and we choose to ignore your breach,
              we shall still be entitled to use our rights and remedies at a
              later date or in any other circumstances where you breach these
              terms again.
            </span>
          </p>

          <h3 id='section-19'>Our trademarks</h3>

          <p>
            ‘Keet’ and ‘Holepunch’ are trademarks of Holepunch Inc. You are not
            permitted to use them without our approval, unless they are part of
            the material you are using as permitted under the section ‘
            <i>
              <a href='#section-5-1'>How you may use material on our Site</a>
            </i>
            ’ above.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Terms
