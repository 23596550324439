import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from 'pages/home'
import Terms from 'pages/terms'

const Pages = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/terms' element={<Terms />} />
      </Routes>
    </Router>
  )
}

export default Pages
