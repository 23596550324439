import Header from 'components/Header'
import Hero from 'components/Hero'
import Video from 'components/Video'
import Features from 'components/Features'
import Holepunch from 'components/Holepunch'
import Footer from 'components/Footer'
import Background from 'components/Background'
import DownloadBanner from 'components/DownloadBanner'

const Home = () => {
  return (
    <>
      <Header />
      <Background>
        <Hero />
        <Video />
      </Background>
      <Background className='bg-repeat-y bg-top'>
        <Features />
        <Holepunch />
        <Footer />
      </Background>
      <DownloadBanner />
    </>
  )
}

export default Home
