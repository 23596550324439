import { Player } from 'video-react'

const PlayButton = ({ player, actions }) => {
  return (
    !player.hasStarted &&
    !!player.currentSrc && (
      <button
        className='absolute left-[50%] top-[50%] -ml-[24px] opacity-60 group-hover:opacity-80'
        type='button'
        aria-live='polite'
        tabIndex='0'
        onClick={() => actions.play()}
      >
        <span className='video-react-control-text-'>
          <svg
            width='38'
            height='48'
            viewBox='0 0 48 58'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M45.125 23.875C46.875 25 48 27 48 29C48 31.125 46.875 33.125 45.125 34.125L9.125 56.125C7.25 57.25 4.875 57.375 3 56.25C1.125 55.25 0 53.25 0 51V7C0 4.875 1.125 2.875 3 1.875C4.875 0.75 7.25 0.75 9.125 2L45.125 23.875Z'
              fill='white'
            />
          </svg>
        </span>
      </button>
    )
  )
}

PlayButton.displayName = 'BigPlayButton'

const Video = () => {
  return (
    <section className='container p-4 md:p-0 m-auto max-w-4xl'>
      <div className='overflow-hidden shadow-xl group'>
        <Player
          src='https://keet.io/videos/keet-explainer.mp4'
          poster='/images/preview-wide.png'
          className='bg-transparent shadow-lg'
        >
          <PlayButton />
        </Player>
      </div>
    </section>
  )
}

export default Video
