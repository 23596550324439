import LogoIcon from 'components/LogoIcon'

const Logo = () => {
  return (
    <div className='flex space-x-3 items-center'>
      <LogoIcon />
      <div className='ml-3 text-lg'>Keet</div>
    </div>
  )
}

export default Logo
