import { OS_MAC, OS_WIN, OS_LINUX, OS_IOS, OS_ANDROID, OS_DESKTOP } from '../constants'

export const getPlatform = () => {
  const userAgent = window.navigator.userAgent

  const isIOS = (!window.MSStream && /iPad|iPhone|iPod/.test(userAgent)) ||
  (navigator.userAgent.includes("Mac") && "ontouchend" in document)

  if(isIOS) {
    return OS_IOS
  }

  if (/Mac/.test(userAgent)) {
    return OS_MAC
  } else if (/Windows/.test(userAgent)) {
    return OS_WIN
  } else if (/Android/.test(userAgent)) {
    return OS_ANDROID
  } else if (/Linux/.test(userAgent) || /\bX11\b/i.test(userAgent)) {
    return OS_LINUX
  }

  // fallback
  return OS_DESKTOP
}
