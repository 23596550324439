import Button from 'components/Button'
import { twMerge } from 'tailwind-merge'

const ButtonPrimary = ({
  onClick = () => {},
  children,
  size = 'sm',
  className,
}) => (
  <Button
    size={size}
    className={twMerge('px-8 text-black bg-cyan1 hover:bg-cyan2 text-sm', className)}
    onClick={onClick}
  >
    {children}
  </Button>
)

export default ButtonPrimary