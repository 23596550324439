import { useRef } from 'react'
import Logo from 'components/Logo'
import useIntersectionObserver from 'hooks/use-intersection-observer'
import DownloadButton from 'components/DownloadButton'

const Header = () => {
  const header = useRef()
  const inView = useIntersectionObserver(header)

  const style = inView ? '-translate-y-20' : '-translate-y-0'

  return (
    <>
      <header ref={header} className='px-6 sm:px-16 z-50'>
        <div className='container m-auto max-w-6xl py-3 flex justify-between items-center'>
          <Logo />
          <DownloadButton text='Download' />
        </div>
      </header>

      <header
        className={`px-6 sm:px-16 z-50 fixed top-0 left-0 w-full bg-header bg-opacity-80 backdrop-blur-xl transition group-main-hover:delay-200 duration-300 ease-in-out ${style}`}
      >
        <div
          className={`container m-auto max-w-6xl py-3 flex justify-between items-center`}
        >
          <Logo />
          <DownloadButton text='Download' />
        </div>
      </header>
    </>
  )
}

export default Header
