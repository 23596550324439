import HolepunchLogo from '../assets/holepunch-big.png'
import Button from 'components/Button'

const Holepunch = () => (
  <section className='container m-auto max-w-6xl px-3 xl:px-0'>
    <div className='bg-hpfill border border-hpstroke rounded-3xl block md:flex p-10'>
      <img alt='' width={400} src={HolepunchLogo} />
      <div className='flex flex-col justify-center items-start'>
        <h3 className='text-3xl'>Powered by Holepunch</h3>
        <h4 className='mb-5 bg-brand2 bg-clip-text text-transparent'>
          The peer-to-peer company
        </h4>
        <p className='text-gray1'>
          Create apps that don’t use servers and can be shipped instantly from
          your computer, directly to your users with zero operational costs.
          When peers control their own data, middlemen are removed, users can
          trust your app to do exactly what it says.
        </p>
        <Button
          className='border border-hpbstroke bg-hpbfill px-6 mt-3'
          size='sm'
          href='https://holepunch.to'
          target='_blank'
        >
          Learn more
        </Button>
      </div>
    </div>
  </section>
)

export default Holepunch
