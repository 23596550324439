import { Link } from 'react-router-dom'
import Modal from 'components/Modal'
import { OS_MAC, CHECKSUMS_URL } from 'constants'
import { OS_ANDROID } from 'constants'
import { OS_IOS } from 'constants'
import { OS_MOBILE } from 'constants'

const Icon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0, 0, 16, 16'
    fill='currentColor'
    stroke='none'
    className='h-4 w-4'
    strokeWidth='1'
  >
    <path d="M8.0001 11.2L4.53343 7.99998M8.0001 11.2L11.2001 7.99998M8.0001 11.2V1.06665M14.4001 7.46665V14.4H1.6001V7.46665" stroke="#000000" strokeWidth="1.0" strokeLinecap="round" fill="none" strokeLinejoin="round"/>
  </svg>
)

const DownloadDialog = ({ open, links, onClose, os }) => {
  const isMobileOs = [OS_ANDROID, OS_IOS, OS_MOBILE].includes(os)
  const renderLink = ({ href, name }, index) => {
    return (
      <a
        key={`link=${index}`}
        href={href}
        className='hover:no-underline group h-24-- rounded-xl flex flex-row justify-between items-center px-6 py-4 hover:bg-cyan2 bg-cyan1 text-black text-sm'
      >
        <div className='flex flex-col justify-start items-start mr-6'>
          <span>{name}</span>
        </div>
        <div className='flex justify-center items-center'>
          <Icon />
        </div>
      </a>
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className='flex flex-col border border-modalborder bg-modalbg px-6 py-8 sm:px-14 sm:py-10 rounded-2xl text-sm xs:text-base space-y-6'>
        <h3 className='text-2xl text-center mb-3'>Get Keet for {os}</h3>
  
        {links.map(renderLink)}

        <span className='text-center text-sm'>
          By downloading Keet, you accept the
          <Link
            to='/terms'
            className='ml-2 text-center text-cyan1 hover:text-cyan2'
          >
            Terms of Service
          </Link>
        </span>

        <span className='flex flex-col sm:flex-row justify-center items-center space-x-4'>
          {os === OS_MAC ? (
            <a
              href='https://support.apple.com/en-us/HT211814'
              target='_blank'
              rel='noreferrer'
              className='text-center text-sm hover:text-cyan2 text-cyan1'
            >
              How do I know which Mac I have?
            </a>
          ) : null}

          {!isMobileOs && (
            <a
              alt='Checksums'
              href={CHECKSUMS_URL}
              target='_blank'
              rel='noreferrer'
              className='text-center text-sm hover:decoration-cyan2 hover:text-cyan2 text-cyan1 hover:decoration-2'
            >
              Checksums
            </a>
          )}


        </span>
      </div>
    </Modal>
  )
}

export default DownloadDialog
