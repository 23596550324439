import { useState } from 'react'

import DownloadButton from './DownloadButton'
import LogoIcon from './LogoIcon'
import CloseImage from '../assets/times.svg'

const DownloadBanner = () => {
  const [show, setShow] = useState(true)
  if (!show) {
    return null
  }
  return (
    <div className='flex fixed bottom-0 left-0 w-full justify-center lg:mb-6 mb-3 z-10'>
      <div className='p-1 mx-3 rounded-lg p-px bg-bannergrad shadow-[0_0px_15px_0px_rgba(67,170,142,0.2)]'>
        <div className='flex justify-center items-center gap-3 p-3 rounded-lg bg-bannerbg'>
          <LogoIcon size={20} />
          <div className='text-bannertext lg:text-base text-sm'>
            Keet BETA is here! Download the app now or open your app to get the latest update.
          </div>
          <div className='lg:mx-4 mx-0'>
            <DownloadButton text='Download Keet' className='bg-bannergrad' />
          </div>
          <button onClick={() => { setShow(false) }}>
            <img alt='Close' width={20} height={20} src={CloseImage} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default DownloadBanner
