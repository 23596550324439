import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import 'styles/fonts.css'
import 'styles/index.css'
import '../node_modules/video-react/dist/video-react.css'
import Pages from 'pages'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    <Pages />
  </StrictMode>
)

