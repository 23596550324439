import { twMerge } from 'tailwind-merge'

const Background = ({ children, className }) => {
  return <div className={
    twMerge('bg-colored bg-contain bg-center bg-no-repeat', className)
  }>
    {children}
  </div>
}

export default Background
