export const VERSION = '2.2.0'

export const OS_MAC = 'Mac'
export const OS_WIN = 'Windows'
export const OS_LINUX = 'Linux'
export const OS_MOBILE = 'Mobile'
export const OS_IOS = 'iOS'
export const OS_ANDROID = 'Android'
export const OS_DESKTOP = 'Desktop'

export const CHECKSUMS_URL = `/downloads/${VERSION}/checksums.txt`

export const LINK_MAP = {
  [OS_WIN]: [
    {
      href: `/downloads/${VERSION}/Keet.msix`,
      name: 'Keet for Windows'
    }
  ],
  [OS_LINUX]: [
    {
      href: `/downloads/${VERSION}/Keet-x64.tar.gz`,
      name: 'Keet for Intel'
    },
    {
      href: `/downloads/${VERSION}/Keet-arm64.tar.gz`,
      name: 'Keet for ARM64'
    }
  ],
  [OS_MAC]: [
    {
      href: `/downloads/${VERSION}/Keet-Apple-Silicon.dmg`,
      name: 'Keet for Apple Silicon'
    },
    {
      href: `/downloads/${VERSION}/Keet-Intel.dmg`,
      name: 'Keet for Intel'
    }
  ],
  [OS_IOS]: [
    {
      href: `https://apps.apple.com/us/app/keet-by-holepunch/id6443880549`,
      name: 'Keet from App Store',
      key: 'ios'
    }
  ],
  [OS_ANDROID]: [
    {
      href: `https://play.google.com/store/apps/details?id=io.keet.app`,
      name: 'Keet from Play Store',
      key: 'android'
    },
  ],
  [OS_MOBILE]: [
    {
      href: `https://apps.apple.com/us/app/keet-by-holepunch/id6443880549`,
      name: 'Keet from App Store',
      key: 'ios'
    },
    {
      href: `https://play.google.com/store/apps/details?id=io.keet.app`,
      name: 'Keet from Play Store',
      key: 'android'
    },
  ],
  [OS_DESKTOP]: [
    {
      href: `/downloads/${VERSION}/Keet.msix`,
      name: 'Windows'
    },
    {
      href: `/downloads/${VERSION}/Keet-x64.tar.gz`,
      name: 'Linux Intel'
    },
    {
      href: `/downloads/${VERSION}/Keet-arm64.tar.gz`,
      name: 'Linux ARM64'
    },
    {
      href: `/downloads/${VERSION}/Keet-Apple-Silicon.dmg`,
      name: 'Apple Silicon'
    },
    {
      href: `/downloads/${VERSION}/Keet-Intel.dmg`,
      name: 'Mac (Intel CPU)'
    }
  ]
}
