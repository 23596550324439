import Feature from 'components/Feature'

import PaymentsImage from '../assets/feature-pay.png'
import VideocallsImage from '../assets/feature-videocalls.png'
import PrivateImage from '../assets/feature-private.png'
import ShareImage from '../assets/feature-share.png'

const CornerLeft = () => (
  <div
    className='hidden md:block bg-no-repeat bg-feature-corner-1 w-[400px] h-[400px] absolute top-0 right-0 bg-center bg-cover'
  />
)

const CornerRight = () => (
  <div
    className='hidden md:block bg-no-repeat bg-feature-corner-2 w-[400px] h-[400px] absolute top-0 left-0 bg-center bg-cover'
  />
)

const Features = () => (
  <div className='container m-auto max-w-6xl mt-20 md:mt-0 mb-20'>
    <div className='relative'>
      <CornerLeft />
      <Feature
        header='Private & encrypted'
        text='Keet only shares end-to-end encrypted data between the participants in every call. Without middlemen, third-parties, or servers, there’s nobody left who can snoop on calls, leak or collect data.'
        img={PrivateImage}
      />
    </div>
    <div className='relative'>
      <CornerRight />
      <Feature
        reverted
        header='Amazing Video Quality'
        text="Keet video calls operate over direct connections between you, your friends or colleagues, without using servers that can throttle your performance. You'll notice the difference immediately."
        img={VideocallsImage}
      />
    </div>
    <div className='relative'>
      <CornerLeft />
      <Feature
        header='Share huge files – instantly'
        text='When you share a file in Keet, you don’t have to wait for it to upload. Other peers can immediately download it straight from the source. Megabytes, gigabytes, or terabytes. No limits.'
        img={ShareImage}
      />
    </div>
    <div className='relative'>
      <CornerRight />
      <Feature
        reverted
        header='Payments Built In'
        text="As your app grows, Holepunch lets you evolve into a business without compromises. With Bitcoin Lightning and USDt micropayments built-in, it's easy to implement and use powerful paid features in apps. Peers control their own data, including how it’s bought and sold. The days of tokens, ads, hidden incentives, and data harvesting are over."
        img={PaymentsImage}
      />
    </div>
  </div>
)

export default Features
