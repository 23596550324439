import { useState } from 'react'
import ButtonPrimary from 'components/ButtonPrimary'
import DownloadDialog from 'components/DownloadDialog'
import { getPlatform } from 'lib/utils'
import { LINK_MAP, OS_MAC } from 'constants'

const DownloadButton = ({ className, size, icon = true, text = null, ...props }) => {
  const os = getPlatform()
  const isMac = os === OS_MAC

  const [show, setShow] = useState(false)
  const links = LINK_MAP[os]

  return (
    <>
      <ButtonPrimary
        {...props}
        size={size}
        className={className}
        onClick={() => links && setShow(true)}
      >
        {text || `Get Keet for ${os}`}
      </ButtonPrimary>

      {links && (
        <DownloadDialog
          open={show}
          links={links}
          isMac={isMac}
          onClose={() => setShow(false)}
          os={os}
        />
        )
      }
    </>
  )
}

export default DownloadButton