import { useState } from 'react'
import { Link } from 'react-router-dom'
import Copyright from 'components/Copyright'
import DownloadDialog from 'components/DownloadDialog'
import { OS_LINUX, LINK_MAP, OS_MAC, OS_WIN, OS_MOBILE } from 'constants'

const Footer = () => {
  const [show, setShow] = useState(false)
  const [os, setOS] = useState(null)
  const links = LINK_MAP[os]

  const linkClassName = 'hover:text-cyan2'
  return (
    <footer className='px-8 xs:px-16 py-20 text-sm'>
      <div className='container m-auto max-w-6xl py-6 flex'>
        <div className='flex flex-1 flex-wrap md:flex-row flex-row justify-between text-gray2'>
          <div className='w-full md:w-1/3 flex-shrink-0 md:pl-20'>
            <Copyright />
          </div>

          <div className='w-1/2 md:w-1/5 flex-shrink-0'>
            <h5 className='mb-4 md:mb-10 antialiased text-gray1 mt-10 md:mt-0' id='applications'>
              Applications
            </h5>
            <ul className='flex flex-col space-y-4 md:space-y-6'>
              <li>
                <a
                  href='#macos'
                  title='Download Keet for Mac'
                  className={linkClassName}
                  onClick={e => {
                    e.preventDefault()
                    setOS(OS_MAC)
                    setShow(true)
                  }}
                >
                  MacOS
                </a>
              </li>
              <li>
                <a
                  href='#linux'
                  title='Download Keet for Linux'
                  className={linkClassName}
                  onClick={e => {
                    e.preventDefault()
                    setOS(OS_LINUX)
                    setShow(true)
                  }}
                >
                  Linux
                </a>
              </li>

              <li>
                <a
                  title='Download Keet for Windows'
                  href='#windows'
                  className={linkClassName}
                  onClick={e => {
                    e.preventDefault()
                    setOS(OS_WIN)
                    setShow(true)
                  }}
                >
                  Windows
                </a>
              </li>
              <li>
                <a
                  href='#mobile'
                  title='Keet for Mobile'
                  className={linkClassName}
                  onClick={e => {
                    e.preventDefault()
                    setOS(OS_MOBILE)
                    setShow(true)
                  }}
                >
                  Mobile
                </a>
              </li>
            </ul>
          </div>
          <div className='w-1/2 md:w-1/5 flex-shrink-0'>
            <h5 className='mb-4 md:mb-10 antialiased text-gray1 mt-10 md:mt-0'>
              Social
            </h5>
            <ul className='flex flex-col space-y-4 md:space-y-6'>
              <li>
                <a
                  title='Twitter'
                  href='https://twitter.com/keet_io'
                  target='_blank'
                  rel='noreferrer'
                  className={linkClassName}
                >
                  Twitter
                </a>
              </li>

              <li>
                <a
                  title='Discord'
                  href='https://discord.com/invite/holepunch'
                  target='_blank'
                  rel='noreferrer'
                  className={linkClassName}  
                >
                  Discord
                </a>
              </li>
            </ul>
          </div>
          <div className='w-1/2 md:w-1/5 flex-shrink-0'>
            <h5 className='mb-4 md:mb-10 antialiased text-gray1 mt-10 md:mt-0'>
              Company
            </h5>
            <ul className='flex flex-col space-y-4 md:space-y-6'>
              <li className='relative'>
                <span className='w-2 h-2 rounded-full bg-red-400 inline-block absolute mr-0 -left-4 top-2' />
                <a
                  title='Join the team'
                  target='_blank'
                  rel='noreferrer'
                  href='https://holepunch.recruitee.com'
                  className={linkClassName}
                >
                  Join the team
                </a>
              </li>
              <li>
                <a
                  title='Get in touch'
                  href='mailto:hello@holepunch.to'
                  className={linkClassName}
                >
                  Get in touch
                </a>
              </li>
              <li>
                <Link
                  to='/terms'
                  title='Terms of Service'
                  className={linkClassName}
                >
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {links && <DownloadDialog
        open={show}
        links={links}
        os={os}
        onClose={() => setShow(false)}
      />}
    </footer>
  )
}

export default Footer
