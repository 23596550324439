const Container = ({ children, reverted }) => {
  const f = reverted ? 'flex-col md:flex-row-reverse' : 'flex-col md:flex-row'
  return (
    <div
      className={`block md:flex ${f} gap-10 px-4 pb-20 md:pl-40 md:pr-40 md:pt-40 md:pb-0`}
    >
      {children}
    </div>
  )
}

const Feature = ({ header, text, img, reverted }) => (
  <Container reverted={reverted}>
    <div className='w-1/2 sm:w-1/3 m-auto md:m-0 md:flex-feature-30 mb-4 md:mb-0'>
      <img alt='' src={img} />
    </div>
    <div className='flex flex-col justify-center'>
      {header && <h3 className='font-semibold text-3xl mb-6'>{header}</h3>}
      <div className='text-gray1'>{text}</div>
    </div>
  </Container>
)

export default Feature
