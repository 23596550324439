import LogoIcon from 'components/LogoIcon'

const Copyright = () => {
  return (
    <span className='flex flex-col space-y-4'>
      <div className='flex items-center'>
        <LogoIcon/>
        <div className='text-2xl ml-2 text-white'>
          Keet
        </div>
      </div>
      <div className='text-lg bg-brand1 bg-clip-text text-transparent'>
        Peer-to-peer chat
      </div>
      <span className='text-white text-sm'>
        Video & Text. Private & Encrypted. 
        <br/>
        <strong>Unparalleled Quality.</strong>
      </span>
    </span>
  )
}

export default Copyright
